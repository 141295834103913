import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border: 1px solid #324455;
  background-color: #1e2c39;
  padding: 18px 13px;
  cursor: pointer;

  button {
    display: none;
  }

  :hover {
    border-color: #7d30e0;
    box-shadow: 0px 10px 25px rgba(125, 48, 224, 0.2);

    button {
      display: block;
      width: 80px;
      background: #7d30e0;
      border: none;
      border-radius: 2px;
      padding: 6.5px 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      cursor: pointer;
      font-family: Montserrat;
      color: #ffffff;
    }
  }

  @media only screen and (max-width: 1200px) {
    min-width: 236px;
  }

  .div-red {
    span {
      color: #dc3545;
    }
  }
`;

export const BoxInfos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentInfos = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentImage = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background-color: #0F1A25;
`;

export const ContentCripto = styled.div`
  margin-left: 10px;

  h1 {
    color: #fafafa;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #5a6473;
    margin: 0;
  }
`;

export const BoxValues = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fafafa;
    margin: 0;
  }

  div {
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #4bd281;
    }
  }
`;
