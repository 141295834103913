import React from "react";

import {
  Container,
  BoxInfos,
  ContentCripto,
  ContentInfos,
  ContentImage,
  BoxValues,
} from "./styles";

function Card({ img, value, abbreviation, name, variation }) {
  const formatBRL = value => {
    const brl = Number(value.replace(".", "")) / 100;
    const brlStr = brl.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return brlStr.replace("R$", "");
  };
  return (
    <Container>
      <BoxInfos>
        <ContentInfos>
          <ContentImage>
            <img src={img} alt="" loading="lazy" />
          </ContentImage>
          <ContentCripto>
            <h1>{abbreviation.toUpperCase()}</h1>
            <h2>{name}</h2>
          </ContentCripto>
        </ContentInfos>
        <button>Comprar</button>
      </BoxInfos>
      <BoxValues className={variation < 0 && "div-red"}>
        <h3>R$ {formatBRL(Number(value).toFixed(2))}</h3>
        {variation && (
          <div>
            {variation >= 0 ? (
              <img src="/up-icon.svg" alt="" loading="lazy" />
            ) : (
              <img src="/down-icon.svg" alt="" loading="lazy" />
            )}
            <span>{Number(variation).toFixed(2).replace(".", ",")} %</span>
          </div>
        )}
      </BoxValues>
    </Container>
  );
}

export default Card;
