import styled from "styled-components";

export const Parent = styled.div`
  font-family: Montserrat, sans-serif;
  margin-bottom: 9px;
  border-radius: 2px;
  width: 350px;
  height: 56px;
  position: relative;

  input:not(:placeholder-shown) + label {
    font-weight: bold;
    font-size: 12px;
    top: 12px;
  }

  input:focus + label {
    font-weight: bold;
    color: #7E3FF2;
    font-size: 12px;
    top: 12px;
  }
`;

export const Input = styled.input`
  font-family: Montserrat, sans-serif;
  color: #fafafa;
  background-color: #1e2c39;
  width: 100%;
  border: 1px solid #394B5C;
  height: 100%;
  padding: 25px 15px 9px 15px;
  transition: border-color 0.25s ease;

  &:focus {
    border-color: #7E3FF2;
    outline: none;
  }
`;

export const Placeholder = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #8D9BAD;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  transition: all 0.25s ease;
`;
