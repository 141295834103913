import styled from "styled-components";
const marker = "/marker.png";

const color = {
  bg: "#0F1A25",
  title: "#FAFAFA",
  body: "#8C96A0",
  highlight: "#7D30E0",
  success: "#4BD281",
  error: "#DC3545",
};

export const Container = styled.div`
  background-color: ${color.bg};
  margin: 0 auto;
  padding: 120px 18vw;
  position: relative;
  transition: 0.2s ease-in-out;
  width: 100%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    transition: 0.2s ease-in-out;
  }

  &::before {
    background-image: url(${marker});
    background-repeat: no-repeat;
    background-size: contain;
    height: 236px;
    left: 0;
    top: 88px;
    width: 118px;
  }

  &::after {
    background-color: ${color.highlight};
    height: 4px;
    left: 82px;
    top: 385px;
    width: 4px;
  }

  * {
    margin: 0;
  }

  @media only screen and (max-width: 1200px) {
    padding: 60px 9vw;

    &::before {
      height: 118px;
      top: 44px;
      width: 59px;
    }

    &::after {
      left: 41px;
      top: 192px;
    }
  }

  @media only screen and (max-width: 600px) {
    &::before {
      height: 59px;
      top: 44px;
      width: 30px;
    }

    &::after {
      left: 20px;
      top: 136px;
    }
  }
`;

export const Label = styled.p`
  color: ${color.highlight};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  color: ${color.title};
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 58px;
`;

export const Content = styled.div`
  color: ${color.body};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding-left: 4vw;

  a {
    color: ${color.highlight};
    text-decoration: underline;
  }

  h2 {
    color: ${color.title};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  ul {
    line-height: 30px;

    li::marker {
      color: ${color.highlight};
    }
  }
`;
