import { useState } from "react";
import { ButtonPattern } from "..";
import Link from "next/link";
import {
  HeaderContent,
  ContainerEnd,
  LogoContainer,
  ContainerMenuMobile,
  LogoContainerMobile,
  BoxLoginMobile,
  MenuContainer,
  HeaderLeftContent,
} from "./styles";

function Header() {
  const [menuMobileShow, setMenuMobileShow] = useState(false);

  return (
    <>
      <HeaderContent manueOpened={menuMobileShow}>
        <HeaderLeftContent>
          <LogoContainer>
            <Link href="/">
              <a>
                <img
                  src={menuMobileShow ? "/logo-white.svg" : "/logo.svg"}
                  width="164"
                  height="27"
                  alt="Logo Braex"
                  loading="lazy"
                />
              </a>
            </Link>
            {menuMobileShow ? (
              <button type="button" onClick={() => setMenuMobileShow(false)}>
                <img
                  src="/close-icon.svg"
                  width="14"
                  height="14"
                  loading="lazy"
                  alt=""
                />
              </button>
            ) : (
              <button type="button" onClick={() => setMenuMobileShow(true)}>
                <img
                  src="/hamburguer-icon.svg"
                  width="18"
                  height="10"
                  loading="lazy"
                  alt=""
                />
              </button>
            )}
          </LogoContainer>

          <nav>
            <ul>
              <li>
                <Link href="/" scroll={false}>
                  <a>Início</a>
                </Link>
              </li>
              <li>
                <Link href="/#negocie" scroll={false}>
                  <a>Negocie</a>
                </Link>
              </li>
              <li>
                <Link href="/#como-funciona" scroll={false}>
                  <a>Como Funciona</a>
                </Link>
              </li>
              <li>
                <Link href="/#exchange" scroll={false}>
                  <a>Exchange</a>
                </Link>
              </li>
              <li>
                <Link href="#vantagens" scroll={false}>
                  <a>Vantagens</a>
                </Link>
              </li>
              <li>
                <Link href="/#contato" scroll={false}>
                  <a>Contato</a>
                </Link>
              </li>
            </ul>
          </nav>
        </HeaderLeftContent>
        <ContainerEnd>
          <a
            href="https://auth.2ndmarket.com.br/"
            target="_blank"
            rel="noreferrer">
            Login
          </a>
          <a
            className="buttonLink"
            href="https://auth.2ndmarket.com.br/cadastro"
            target="_blank"
            rel="noreferrer">
            Cadastre-se
          </a>
        </ContainerEnd>
      </HeaderContent>

      {menuMobileShow && (
        <ContainerMenuMobile>
          <LogoContainerMobile>
            <img
              src="/logo-mobile.svg"
              width="202"
              height="31"
              loading="lazy"
              alt="Logo Braex"
            />
            <button type="button" onClick={() => setMenuMobileShow(false)}>
              <img
                width="14.5"
                height="14.5"
                src="/close-icon.svg"
                loading="lazy"
                alt=""
              />
            </button>
          </LogoContainerMobile>
          <MenuContainer>
            <ul>
              <li>
                <Link href="/#inicio">
                  <a onClick={() => setMenuMobileShow(false)}>Início</a>
                </Link>
              </li>
              <li>
                <Link href="/#negocie">
                  <a onClick={() => setMenuMobileShow(false)}>Negocie</a>
                </Link>
              </li>
              <li>
                <Link href="/#como-funciona">
                  <a onClick={() => setMenuMobileShow(false)}>Como Funciona</a>
                </Link>
              </li>
              <li>
                <Link href="/#exchange">
                  <a onClick={() => setMenuMobileShow(false)}>Exchange</a>
                </Link>
              </li>
              <li>
                <Link href="/#vantagens">
                  <a onClick={() => setMenuMobileShow(false)}>Vantagens</a>
                </Link>
              </li>
              <li>
                <Link href="/#contato">
                  <a onClick={() => setMenuMobileShow(false)}>Contato</a>
                </Link>
              </li>
            </ul>
            <BoxLoginMobile>
              <a
                href="https://auth.2ndmarket.com.br/"
                target="_blank"
                rel="noreferrer">
                Login
              </a>
              <button
                type="button"
                href="https://auth.2ndmarket.com.br/cadastro"
                target="_blank"
                rel="noreferrer">
                Cadastre-se
              </button>
            </BoxLoginMobile>
          </MenuContainer>
        </ContainerMenuMobile>
      )}
    </>
  );
}

export default Header;
