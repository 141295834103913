import styled from "styled-components";

export const Cartola = styled.h2`
  color: ${props => (props.color ? props.color : "#B794F6")};
  font-weight: ${props => (props.weight ? props.weight : "500")};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: ${props => (props.size ? props.size : "42px")};
  font-weight: ${props => (props.weight ? props.weight : "bold")};
  line-height: ${props => (props.height ? props.height : "50px")};
  color: ${props => (props.color ? props.color : "#FAFAFA")};
  margin: 0;
`;

export const Subtitle = styled.h3`
  font-size: ${props => (props.size ? props.size : "16px")};
  font-weight: ${props => (props.weight ? props.weight : "500")};
  line-height: ${props => (props.height ? props.height : "22px")};
  color: #fafafa;
  margin-bottom: 6px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: ${props => (props.color ? props.color : "#C6CFDB")};
  margin: 15px 0;
`;

export const Additional = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.color ? props.color : "#FAFAFA")};
  margin: 24px 0;
`;

export const Strong = styled.strong`
  font-weight: 600;
  color: ${props => (props.white ? "white" : "#C6CFDB")};

  margin-left: ${props => (props.marginLeft ? props.marginLeft : "0px")};
`;
