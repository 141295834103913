import styled from "styled-components";

export const Container = styled.div`
  max-width: 28vw;
  min-height: 268px;
  padding: 32px 28px;
  border: 1px solid #324455;
  border-radius: 2px;

  p {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    min-width: 84vw;
    min-height: 300px;
  }
`;

export const ImageContainer = styled.div`
  padding: 13px;
  background-color: #1e2c39;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 13px;
`;
