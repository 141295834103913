import { Container, ImageContainer } from "./styles";
import { Subtitle, Paragraph } from "../../components/Templates/texts";

function Card({ src, title, paragraph }) {
  return (
    <Container>
      <ImageContainer>
        <img src={src} width="34" height="34" loading="lazy" alt="" />
      </ImageContainer>
      <Subtitle>{title}</Subtitle>
      <Paragraph>{paragraph}</Paragraph>
    </Container>
  );
}

export default Card;
