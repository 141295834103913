import styled from "styled-components";

export const FooterContainer = styled.footer`
	display: flex;
	flex-direction: column;
	background-color: #0F1A25;
	padding: 80px 0 0 6vw;
	position: relative;

	.squares {
		position: absolute;
		right: 10%;
	}

	@media only screen and (max-width: 1025px) {
		flex-direction: column;
		padding: 50px 4vw 0 4vw;

		.squares {
			display: none;
		}
	}
`;

export const FooterContent = styled.div`
	margin-top: 16px;
	background-color: #0F1A25;
	display: flex;
	gap: 10vw;

	@media only screen and (max-width: 1025px) {
		flex-direction: column;
	}
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;

	a > img {
		margin: 0;
	}

	div {
		display: flex;
		gap: 40px;
	}

	@media only screen and (max-width: 1025px) {
		gap: 28px;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		a {
			margin: 0;
		}

		div {
			gap: 25px;
		}
	}
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	gap: 9px;
	margin-left: 260px;

	p {
		font-weight: 500;
		font-size: 14px;
		color: #B794F6;
		margin: 0 0 10px 0;
	}

	a {
		font-weight: 500;
		font-size: 14px;
		text-decoration-line: none;
		color: #C6CFDB;
		transition: color 0.25s;

    img {
      margin-left: 10px;
    }

		&:hover {
			color: #B794F6;
		}
	}

	div {
		display: flex;
		gap: 40px;
		margin-top: 45px;

		@media only screen and (max-width: 1025px) {
			margin: 40px 0 35px 0;
		}
	}

	@media only screen and (max-width: 1025px) {
		margin: 39px 0;

		img {
			width: fit-content;
		}
	}
`;

export const TextsContainer = styled.div`
	color: #C6CFDB;

	.links {
		a {
			color: #C6CFDB;
			padding: 0 7px;
			border-left: 1px solid #C6CFDB;
			transition: 0.5s;

			:first-child {
				padding: 0 7px 0 0;
				border: none;
			}

			:hover {
				color: #B794F6;
				transition: 0.5s;
				text-decoration: none;
			}
		}

		@media only screen and (max-width: 1025px) {
			margin-bottom: 20px;
		}
	}

	.logoFooter {
		img {
			margin: 30px 0;
		}
	}

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: #C6CFDB;
		margin: 0;
	}
`;

export const SingleLink = styled.a`
	color: #B794F6;
	margin: 0px 5px;

	&:hover {
		text-decoration: underline;
	}
`;

export const BrandLink = styled.a`
	color: #B794F6;

	&:hover {
		text-decoration: underline;
	}
`;
