import styled from "styled-components";

export const HeaderContent = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 19px 6vw 10px;
	position: sticky;
	background-color: ${props =>
    props.manueOpened
      ? '#7E3FF2'
      : '#0F1A25'};
	top: 0;
	z-index: 3;
	box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		padding: 28px 4vw;
		z-index: 5;
    box-shadow: none;
	}

	nav {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	nav ul {
		display: flex;
		gap: 40px;
		list-style-type: none;

		@media only screen and (max-width: 1200px) {
			display: none;
		}

		a {
			font-weight: 500;
			font-size: 14px;
			color: #fafafa;
			text-decoration: none;
      white-space: nowrap;
			transition: color 0.25s ease-in-out;

			&:hover {
				color: #7E3FF2;
			}
		}
	}
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;

  @media only screen and (max-width: 1200px) {
    justify-content: space-between;
    width: 100%;
    gap: 0;
  }
`

export const LogoContainer = styled.div`
	display: flex;
	justify-content: space-between;
  
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

	button {
		border: none;
		background: transparent;
		width: 42px;

		@media only screen and (min-width: 1200px) {
			display: none;
		}
	}

	a {
		display: flex;
		align-items: center;

    img {

      @media only screen and (max-width: 1200px) {
        width: 120px;
        height: 20px;
      }
    }
	}
`;

export const ContainerEnd = styled.div`
	display: flex;
	align-items: center;
  gap: 40px;

	ul {
		list-style-type: none;
		display: flex;

		li {
			margin-left: 20px;

			a {
				font-weight: 500;
				margin-right: 30px;
				color: #fafafa;
				text-decoration: none;
				transition: color 0.25s ease-in-out;

				&:hover {
					color: #7E3FF2;
				}
			}
		}
	}

	span {
		font-weight: 500;
		margin-right: 37px;
		color: #fafafa;
		text-decoration: none;
		transition: color 0.25s ease-in-out;

		&:hover {
			color: #7E3FF2;
		}
	}

	a {
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
    text-decoration: none;
    white-space: nowrap;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: #7E3FF2;
    }

    &.buttonLink {
      background-color: #7E3FF2;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      color: #ffffff;
      padding: 15px 2vw;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      transition: 0.25s;
      white-space: nowrap;
  
      &:hover {
        background-color: #551aa2;
        transition: 0.5s;
        color: #ffffff;
      }
    }
	}

	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

export const ContainerMenuMobile = styled.div`
	background-color: #7E3FF2;
	height: 101%;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 3;

	button {
		background: transparent;
		box-shadow: 0px 10px 25px rgba(70, 220, 175, 0.1);
		border: none;
	}

	ul {
		list-style-type: none;
		margin: 0 4vw;
		padding: 0;

		li {
			margin-bottom: 13px;

			a {
				font-size: 14px;

				color: #ffffff;
				text-decoration: none;
			}
		}
	}
`;

export const LogoContainerMobile = styled.div`
	display: flex;
	margin: 28px 4vw 70px 4vw;
	justify-content: space-between;
	align-items: center;

	button {
		margin: 0;
		display: flex;
		align-items: center;
	}
`;

export const BoxLoginMobile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 30px;

	span {
		font-size: 14px;
		color: #ffffff;
		margin-bottom: 30px;
		margin-top: 50px;
	}

  a {
    display: block;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 20px 10px;
    transition: 0.5s;

    &:hover {
			background-color: #ffffff;
			color: #7E3FF2;
		}
  }

	button {
		width: 100%;
		background-color: #ffffff;
		border: none;
		border-radius: 2px;
		cursor: pointer;
		color: #7E3FF2;
		padding: 15px 2vw;
		font-family: Montserrat;
		font-weight: 500;
		font-size: 14px;
		transition: 0.25s;
		white-space: nowrap;
		text-align: center;
    transition: 0.5s;

		&:hover {
			background-color: #7E3FF2;
			color: #ffffff;
		}
	}
`;

export const MenuContainer = styled.div`
	height: 75%;
	display: flex;
	flex-direction: column;
`;
