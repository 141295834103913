import Link from "next/link";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import ProductCnpjs from "@2ndmarket/components/src/helper/ProductCnpjs";
import { BraexSocials } from "@2ndmarket/components/src/helper/ProductSocialNetworks";

import {
  FooterContent,
  SocialMediaContainer,
  TextsContainer,
  Box,
  BrandLink,
  FooterContainer,
} from "./styles";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <SocialMediaContainer>
          <Link href="/">
            <a rel="noreferer">
              <img
                loading="lazy"
                src="/logo.svg"
                alt="2ndmarket"
                width={164}
                height={27}
              />
            </a>
          </Link>
          <div>
            <Link href={BraexSocials.FACEBOOK}>
              <a target="_blank" rel="noreferer">
                <img loading="lazy" src="/facebook-footer.svg" alt="facebook" />
              </a>
            </Link>
            {BraexSocials.LINKEDIN ? (
              <Link href={BraexSocials.LINKEDIN}>
                <a target="_blank" rel="noreferer">
                  <img
                    loading="lazy"
                    src="/linkedin-footer.svg"
                    alt="linkedin"
                  />
                </a>
              </Link>
            ) : null}
            <Link href={BraexSocials.INSTAGRAM}>
              <a target="_blank" rel="noreferer">
                <img
                  loading="lazy"
                  src="/instagram-footer.svg"
                  alt="instagram"
                />
              </a>
            </Link>
          </div>
        </SocialMediaContainer>

        <Box>
          <p>Nosso ecossitema:</p>
          <Link href={ProductUrls.SECOND_MARKET}>
            <a target="_blank" rel="noreferrer">
              2ND Market
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.BANKAI}>
            <a target="_blank" rel="noreferrer">
              Bank.ai
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.CUSTON}>
            <a target="_blank" rel="noreferrer">
              Cust.on
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.TOKENAIM}>
            <a target="_blank" rel="noreferrer">
              Token.aim
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
        </Box>
      </FooterContent>
      <TextsContainer>
        <p className="links">
          <Link href={ProductUrls.PRIVACY_POLICY}>
            <a target="_blank" rel="noreferrer">
              Política de Privacidade
            </a>
          </Link>
          <Link href={ProductUrls.TERMS_AND_CONDITIONS}>
            <a target="_blank" rel="noreferrer">
              Termos de Uso
            </a>
          </Link>
          <Link href={ProductUrls.SUPPORT}>
            <a target="_blank" rel="noreferrer">
              Preciso de Ajuda
            </a>
          </Link>
        </p>
        <p>
          © {currentYear}{" "}
          <BrandLink href={ProductUrls.SECOND_MARKET}>Second Market</BrandLink>{" "}
          - Todos direitos reservados
        </p>
        <p>CNPJ {ProductCnpjs.BRAEX}</p>

        <div className="logoFooter">
          <img
            src="../logo-2nd-footer.svg"
            width="143"
            height="21"
            alt="Logo 2ndMarket"
          />
        </div>
      </TextsContainer>

      <div className="squares">
        <img src="../footer-decoration.svg" alt="" width={287} height={187} />
      </div>
    </FooterContainer>
  );
}

export default Footer;
