import React from "react";

import { Container, Submit } from "./styles";

function ButtonPattern({ children, anchor, onClick }) {
  return (
    <>
      {anchor ? (
        <Container>
          <a href={anchor}>{children}</a>
        </Container>
      ) : (
        <Submit type="submit" value={children} />
      )}
    </>
  );
}

export default ButtonPattern;
